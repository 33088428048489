p-radio-button,
p-radiobutton,
p-radioButton {
    display: inline-flex;
}

p-tree {
    .p-tree-node-checkbox {
        display: block;
    }
}

p-treetable {
    .p-treetable-node-checkbox {
        display: block;
    }
}

.p-tag-icon {
    line-height: 1.2;
}

.p-checkbox {
    display: block !important;
}
