* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: 14px;
    line-height: 1.5;
}

body {
    font-weight: 400;
    padding: 0;
    margin: 0;
    min-height: 100%;
    background: var(--surface-ground);
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
    font-feature-settings: "cv02","cv03","cv04","cv11";
    font-variation-settings: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

.layout-container {
    min-height: 100vh;
}
