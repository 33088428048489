@import "intl-tel-input/build/css/intlTelInput.css";

.mat-input-label {
  padding-left: 54px !important;
}

.iti {
  width: 100%;
}

.field-group {
  position: relative;
  margin-top: 22px;
  margin-bottom: 22px;
}

.mat-floating-label {
  position: absolute;
  top: -3px;
  left: 55px;
  font-size: 14px;
  font-weight: 400;
  color: #999;
  pointer-events: none;
  transition: transform 0.2s ease-out;
  transform-origin: top left;
  padding: 0;
}

.mat-text-field {
  position: relative;
  width: 100%;
  height: 56px;
  border: 1px solid #999;
  border-radius: 4px;
  background-color: #F5F5F5;
  padding: 20px 0;
  box-sizing: border-box;
}

.mat-text-field-fill {
  position: relative;
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #999;
  border-radius: 4px;
  background-color: #F5F5F5;
  padding: 20px 0;
  box-sizing: border-box;
}

.mat-text-field__input {
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #333;
  border: none;
  background-color: transparent;
  outline: none;
}
