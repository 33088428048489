.layout-topbar {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .topbar-start {
        display: flex;
        align-items: center;

        .topbar-menubutton {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 2rem;
            border-radius: 50%;
            width: 2.5rem;
            height: 2.5rem;
            flex-shrink: 0;
            background-color: transparent;
            border: transparent;
            transition: background-color var(--layout-section-transition-duration);

            i {
                font-size: 1.25rem;
                color: var(--text-color);
                transition: color var(--layout-section-transition-duration);
            }

            &:hover {
                background-color: var(--primary-color);

                i {
                    color: var(--primary-color-text);
                }
            }
        }
    }

    .topbar-menu {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
        gap: 1rem;

        li {
            &.topbar-profile {
                display: inline-flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100%;
                    height: 100%;
                }

                button {
                    background-color: transparent;
                    margin: 0;
                    padding: 0;
                    border: none;
                    border-radius: 50%;
                    width: 2.5rem;
                    height: 2.5rem;
                    transition: box-shadow var(--layout-section-transition-duration);

                    &:focus {
                        box-shadow: var(--focus-ring);
                    }
                }
            }

            &.topbar-search {
                .p-inputtext {
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    border-radius: 2rem;
                }
            }
        }
    }
}

.config-panel {
    .config-panel-label {
        font-size: 0.875rem;
        color: var(--text-secondary-color);
        font-weight: 600;
        line-height: 1;
    }

    .config-panel-colors {
        > div {
            padding-top: 0.5rem;
            display: flex;
            gap: 0.5rem;
            flex-wrap: wrap;
            justify-content: space-between;

            button {
                border: none;
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 50%;
                padding: 0;
                cursor: pointer;
                outline-color: transparent;
                outline-width: 2px;
                outline-style: solid;
                outline-offset: 1px;

                &.active-color {
                    outline-color: var(--primary-color);
                }
            }
        }
    }

    .config-panel-settings {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}
