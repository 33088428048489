/* Layout */
@use '../../../libs/pm-core/pm-core-layout/src/assets/styles/styles.scss';

// @tailwind base;
// @tailwind components;
// @tailwind utilities;

/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system


@import 'flag-icons/css/flag-icons.min.css';

/* PrimeNG */
@import 'primeflex/primeflex.css';

@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';

:root {
  --pale-blue: #f2f5fd;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

body {
  position: relative;
  padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px)
    env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px);
  padding-top: var(--safe-area-inset-top);
  padding-bottom: var(--safe-area-inset-bottom);

  .status-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: var(--safe-area-inset-top);
    background-color: var(--p-surface-ground);
  }
}

.safe-area-inset-top {
  margin-top: calc(var(--safe-area-inset-top) + 16px);
}

.p-inputotp {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.p-inputotp-input {
  text-align: center;
  width: 2.5rem;
}

.p-paginator-pages {
  @media (max-width: 768px) {
    display: none;
  }
}
